import React from 'react'
import styled from 'styled-components'
import RemarkRenderer from 'components/RemarkRenderer'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify};

  /* default styles */
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.5rem 0 2rem 0;
  }
  & li {
    line-height: 1.8rem;
  }
`

export default function tileRichText({ data }) {
  return (
    <Wrapper justify={data.alignment}>
      {data.title && <div className="title">{data.title}</div>}
      <RemarkRenderer
        flexFill={{ alignItems: data.alignment }}
        {...(data.clamp && { clamp: 'auto' })}
        ast={data.content.childMarkdownRemark.htmlAst}
      />
    </Wrapper>
  )
}
